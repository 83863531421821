import request from '@/found/utils/request';
import Storage from '@/found/utils/storage';
import Form, { formCreate } from '@/found/components/form';
import TableSelect from '../components/tableSelect.vue';

formCreate.component('TableSelect', TableSelect);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formParentCode: 'CRM20230313000000002',
      formFunctionCode: 'external_employees_change',
      buttonText: {
        submit: '保存',
      },
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'identityCardNumber') {
        item.props = {
          ...item.props,
          maxlength: 23,
        };
      }
      if (item.field === 'ext13') {
        const paramsObj = {
          functionCode: 'dealer_list',
          data: [],
          selectionList: [],
          idKey: 'customerCode',
          noReset: true,
          paramData: { enableStatus: '009', actApproveStatus: 3 },
        };
        item.props = {
          ...item.props,
          paramsObj,
          placeholder: '所属经销商',
        };
      }
      if (item.field === 'parentCode') {
        item.restful = '/mdm/mdmUserExtController/sfaFindUserParentPositionSelectList';
        item.restfulParams = { enableStatus: '009' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'unionName',
        };
        item.optionsKey = {
          label: 'unionName',
          value: 'positionCode',
          parentCode: 'positionCode',
          parentName: 'positionName',
          orgCode: 'orgCode',
          positionFullName: 'positionFullName',
          userName: 'userName',
        };
        item.on = {
          ...item.on,
          getItem: (val) => {
            this.setValue({
              positionFullName: val.fullName,
              unionName: val.unionName,
              userName: val.userName,
              parentCode: val.positionCode,
              parentName: val.positionName,
              orgCode: val.orgCode,
            });
          },
        };
      }
      if (item.field === 'positionLevelCode') {
        item.restful = '/mdm/mdmPositionExtController/sfaSelectList';
        item.restfulParams = { enableStatus: '009' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'positionLevelName',
        };
        item.optionsKey = {
          label: 'positionLevelName',
          value: 'positionLevelCode',
        };
        item.on = {
          ...item.on,
          getItem: (val) => {
            this.setValue({ positionLevelName: val.positionLevelName, positionLevelCode: val.value });
          },
        };
      }
      if (item.field === 'startTime') {
        item.props = {
          ...item.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: '00:00:00',
        };
      }
      if (item.field === 'endTime') {
        item.props = {
          ...item.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: '23:59:59',
        };
      }
      if (item.field === 'sfaAttachUrls') {
        item.props = {
          ...item.props,
          limit: 999,
          fileMsg: '只能上传jpg/png文件',
          accept: '.png,.jpg,.jpeg',
          fileSize: '10',
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    async formComplete() {
      const positionLevelCode = this.getRule('positionLevelCode');
      const sfaAttachUrls = this.getRule('sfaAttachUrls');
      positionLevelCode.on.change = (val) => {
        console.log(val);
        if (val === 'ZWJB000283') {
          this.hiddenFields(true, 'sfaAttachUrls');
        } else {
          this.hiddenFields(false, 'sfaAttachUrls');
        }
      };
      const ext13 = this.getRule('ext13');
      ext13.on.change = (val) => {
        this.setValue({
          ext13: val.customerName,
          ext12: val.customerCode,
        });
      };
      request
        .get('/sfa/sfaMdmUserController/detail', {
          id: this.formConfig.row.id || '',
        })
        .then((res) => {
          if (res.success) {
            let data = {};
            if ((res.result.positionList[0] || []).positionLevelCode === 'ZWJB000283') {
              this.hiddenFields(true, 'sfaAttachUrls');
            } else {
              this.hiddenFields(false, 'sfaAttachUrls');
            }
            if (res.result.enableStatus === '009') {
              this.disabled(true, 'enableStatus');
            } else if (res.result.enableStatus === '003') {
              this.disabled(false, 'enableStatus');
            }
            data = {
              fullName: res.result.fullName,
              identityCardNumber: res.result.identityCardNumber,
              ext12: res.result.ext12,
              ext13: res.result.ext13,
              startTime: res.result.startTime,
              endTime: res.result.endTime,
              userName: this.formConfig.row.parentFullName ? (res.result.positionList[0] || []).userName : '',
              unionName: (res.result.positionList[0] || []).unionName || '',
              positionFullName: (res.result.positionList[0] || []).fullName || '',
              parentCode: (res.result.positionList[0] || []).parentCode,
              parentName: (res.result.positionList[0] || []).parentName,
              orgCode: (res.result.positionList[0] || []).orgCode,
              positionLevelCode: (res.result.positionList[0] || []).positionLevelCode || '',
              positionLevelName: (res.result.positionList[0] || []).positionLevelName || '',
              sfaAttachUrls: res.result.sfaAttachUrls,
              userPhone: res.result.userPhone,
              gender: res.result.gender || '0',
              id: res.result.id,
              enableStatus: res.result.enableStatus,
            };
            this.setValue(data);
          }
        });
    },

    // 表单提交
    submit() {
      let formData = this.getFormData();
      formData = {
        ...formData,
        positionList: [
          {
            userName: formData.userName,
            unionName: formData.unionName,
            fullName: formData.parentFullName,
            parentCode: formData.parentCode,
            parentName: formData.parentName,
            orgCode: formData.orgCode,
            positionLevelCode: formData.positionLevelCode,
            positionLevelName: formData.positionLevelName,
            positionCode: this.formConfig.row.positionCode ? this.formConfig.row.positionCode : '',
            positionName: this.formConfig.row.positionName ? this.formConfig.row.positionName : '',
          },
        ],
        id: this.formConfig.row.id || '',
      };
      delete formData.userName;
      delete formData.unionName;
      delete formData.parentFullName;
      delete formData.parentCode;
      delete formData.parentName;
      delete formData.orgCode;
      delete formData.positionLevelCode;
      delete formData.positionLevelName;
      delete formData.positionCode;
      delete formData.positionName;
      if (formData) {
        const url = '/sfa/sfaMdmUserController/modify';
        const params = formData;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
